var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-back",
      style: {
        background:
          "url(" +
          require("@/assets/images/testlogin_back.gif") +
          ")" +
          "no-repeat"
      }
    },
    [
      _c("img", {
        staticClass: "logo_zhineng",
        attrs: { src: require("@/assets/images/zhineng_logo.png"), alt: "" }
      }),
      _c("div", { staticClass: "login_outer" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("智能化多媒体管理和生产系统")
        ]),
        _c(
          "div",
          { staticClass: "login-container" },
          [
            _vm._m(0),
            _c(
              "el-form",
              {
                ref: "dynamicValidateForm",
                staticClass: "demo-dynamic",
                attrs: { model: _vm.dynamicValidateForm, rules: _vm.rules }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "email", error: _vm.error } },
                  [
                    _c("el-input", {
                      attrs: { type: "email", placeholder: "邮 箱" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.register("dynamicValidateForm")
                        }
                      },
                      model: {
                        value: _vm.dynamicValidateForm.email,
                        callback: function($$v) {
                          _vm.$set(_vm.dynamicValidateForm, "email", $$v)
                        },
                        expression: "dynamicValidateForm.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("el-input", {
                      attrs: { type: "password", placeholder: "密 码" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.register("dynamicValidateForm")
                        }
                      },
                      model: {
                        value: _vm.dynamicValidateForm.password,
                        callback: function($$v) {
                          _vm.$set(_vm.dynamicValidateForm, "password", $$v)
                        },
                        expression: "dynamicValidateForm.password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "test",
                        staticStyle: { width: "20.21vw", height: "4.3vh" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.register("dynamicValidateForm")
                          }
                        }
                      },
                      [_vm._v("注册")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "bottom" }, [
              _c("div"),
              _c(
                "div",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.goLogin }
                },
                [_vm._v("已有账号，去登录")]
              )
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-or-register" }, [
      _c("span", { staticClass: "re" }, [_vm._v("注册")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }